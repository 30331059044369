@import 'style/global.scss';

#alpha_banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: medium;
  padding: 1rem;
  z-index: -1;
  text-align: center;
  background-color: black;
  opacity: 0.7;
  color: white;
  a,
  a:visited {
    color: white;
  }

  position: relative;

  button {
    max-width: 270px;
  }
}

#top_nav {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  @include is_tablet() {
    padding: 1rem;
  }

  #nav_links {
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style: none;

    a {
      text-decoration: none;
      font-family: 'Roboto Mono', sans-serif;
      font-size: 1.4rem;
      color: #223d40;
      font-weight: 550;
      &:hover {
        color: black;
      }
    }

    @include is_tablet() {
      a {
        font-size: 1.3rem;
      }
    }
  }

  .but {
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
    border-radius: 25px;
    transition-duration: 0.3s;

    &:hover {
      background-color: rgb(244, 244, 244);
    }
  }

  #nav_searchbar {
    margin-right: auto;
    min-width: 20%;
    max-width: 25%;
    padding-left: 2%;
  }

  #nav_auth {
    margin-left: auto;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    #nav_avatar {
      max-width: 40px;
      // border-radius: 5rem;
    }

    a {
      padding: 0.5rem 1.6rem;

      font-weight: 700;
      text-decoration: none;

      color: black;
    }

    .nav_register {
      background-color: black;
      color: white;
      border-radius: 5px;
      padding: 1rem 1.6rem;
    }
  }
}
