@import 'style/global.scss';

.gallery_card {
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  z-index: 10;

  display: flex;

  position: relative;

  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  color: #7c7c7c;

  .clickable_nft_section {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    border-radius: 10px;
  }
}
