#page_404 {
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error_name {
    font-size: 55px;

    text-align: center;
  }

  .error_code {
    font-size: 21px;
    text-align: center;
  }
}
