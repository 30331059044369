@import 'style/global.scss';
.cover_upload {
  width: 200px;
  height: 200px;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
}
#cover_but {
  position: absolute;
  right: 3%;
  bottom: 30%;

  @include is_phone() {
    bottom: 60%;
  }

  .the_but {
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 50px;
    cursor: pointer;
    &:hover {
      background-color: rgb(222, 222, 222);
    }
  }
}
