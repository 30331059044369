@import 'style/global.scss';

.collection_container {
  margin: 3rem;
  // margin-top: 4rem;
  #collec {
    cursor: pointer;
  }
}

.artwork_header {
  position: relative;
  height: 80vh;
  min-height: 550px;
  background-color: rgb(244, 244, 244);

  @include is_phone() {
    height: 40vh;
  }
  &_ShareButton {
    position: absolute;
    right: 7rem;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    button {
      &:hover {
        background: none;
      }
    }
    svg {
      font-size: x-large;
    }
    &:hover {
      background-color: gainsboro;
    }
  }

  .artwork_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // max-width: 80%;
    height: auto;
    // max-height: 80%;
  }

  .full_screen {
    position: absolute;
    // margin: 1rem;
    // padding:1rem;
    right: 2%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    margin: auto;

    font-size: x-large;
    font-weight: 500;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: gainsboro;
    }

    svg {
      display: block;
      margin: auto;
      height: 100%;
    }
  }

  .full_screen_container {
    display: none;
    width: 100%;
    height: 100%;
  }

  .fullscreen-enabled .full_screen_container {
    background-color: rgb(244, 244, 244);
    display: block;
  }

  .edition {
    position: absolute;
    right: 2%;
    top: 5%;

    font-size: x-large;
    font-weight: 500;
  }

  .link_bef {
    cursor: pointer;
    position: absolute;
    left: 2%;
    top: 4%;
    font-size: x-large;
    font-weight: 500;
    border-radius: 50%;
    padding: 0.5rem;
  }
}

.artwork_details {
  padding-top: 2rem;
  min-height: 50vh;
  padding-left: 4rem;
  background-color: white;
  &_title {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  .polygon_link {
    cursor: pointer;
    display: flex;
  }

  .sub {
    // font-family: 'Courier New', Courier, monospace;
    font-weight: 300;
    color: #656565;
    margin-right: 1rem;
    // margin-top: 1rem;
  }

  h1 {
    @include is_phone() {
      font-size: 3rem;
    }

    // padding-bottom: 1rem;
    font-size: 4rem;
    text-align: left;
    padding-top: 1rem;
    margin: 0;
  }

  h2 {
    text-align: left;
    text-transform: none;
    font-weight: 600 !important;
    // color: rgb(132, 132, 132);
    font-size: large;
    margin-bottom: 1rem;
    // margin-top:8rem;
    // margin-bottom:4rem;
  }

  .row {
    padding-top: 2rem;
    display: flex;
    font-weight: 650;
    font-size: medium;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .col {
    width: 45%;
    margin: 2rem;

    @include is_phone() {
      width: 100%;
    }
  }

  .elem {
    margin-top: 0.2rem;
    font-size: large !important;
    color: rgb(29, 29, 29);
    font-weight: 700;
  }
}

.artwork_subtitle {
  color: rgb(132, 132, 132);
  font-size: large;
  font-weight: 550;
  margin: 0;
}

// CSS TEST

#dark-light-container > div {
  float: left;
  box-sizing: border-box;
  position: relative;
  padding: 2rem;
  width: 50%;
  text-align: center;
}

.white-container {
  background: white;
}

.black-container {
  background: black;
}

.contain {
  @include is_phone() {
    width: 25rem;
    height: 25rem;
  }

  width: 45rem;
  height: 45rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  box-shadow: none;
  backface-visibility: visible;
  background: transparent;
  transform-style: preserve-3d;
  padding: 0;
  margin: 0 2rem 4rem 0;
  //   width: 18rem;
  //   height: 25rem;
  float: right;
  transition: all 0.2s ease-out;
  border: none;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 100%;
    margin: auto;
  }
}

.flip,
.unflip {
  background: rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 100px;
  line-height: 1rem;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.blastoise .flip,
.blastoise .unflip {
  background: #1b29ed;
  box-shadow: 0 2px 20px #0b1485;
}

.blastoise .inner-card-backface .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 14px;
  height: 100%;
  // background-size: auto 102%;
  // background-position: -2px -5px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.unflip {
  top: auto;
  background: #2d2d62;
  bottom: 1rem;
}

.flip:hover {
  background: rgba(0, 0, 0, 0.3);
}

.blastoise .flip:hover,
.blastoise .unflip:hover {
  background: #0f1cc5;
}

.inner-card-backface {
  transform: rotateX(0) rotateY(0deg) scale(1) translateZ(-4px);
  border-radius: 14px;
  // background: linear-gradient(45deg, #0b0b2a, #0b0b2a);
  position: absolute;
  top: 0;
  color: white;
  padding: 2rem;
  box-sizing: border-box;
  transition: all 0.15s ease-out;
  will-change: transform, filter;
  left: 0;
  width: 100%;
  height: 100%;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card .flip-inner-card {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  height: 100%;
}

.flip-inner-card h3 {
  font-size: 1.25rem;
  margin-top: 0;
}

.flip-inner-card p {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  line-height: 1.5rem;
}

.card .inner-card {
  font-size: 2rem;
  color: white;
  padding: 1rem 2rem;
  line-height: 3rem;
  will-change: transform, filter;
  float: none;
  // background: rgb(0, 0, 0);
  background-size: calc(100% + 6px) auto;
  background-position: -3px -3px;
  margin: 0;
  transition: all 0.15s ease-out;
  height: auto;
  border-radius: 14px;
  box-sizing: border-box;
  overflow: hidden;
  display: block;
  margin: 0px auto;
  transform: rotateX(0deg) rotateY(0deg) scale(1);
  top: 0;
  height: 100%;
  filter: drop-shadow(0 15px 15px rgba(0, 0, 0, 0.3));
  font-weight: 500;
  perspective-origin: 0 0;
  letter-spacing: 0;
}

.card .glare {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.1s ease-out;
  opacity: 0.6;
  pointer-events: none;
  height: 100%;
  border-radius: 14px;
  z-index: 9999;
  mix-blend-mode: hard-light;
  background: radial-gradient(circle at 50% 50%, rgb(199 198 243), transparent);
}

.card .top-section {
  background: linear-gradient(45deg, hsl(0deg 58% 51%), hsl(249deg 100% 54%));
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 14px 14px 0 0;
  width: 100%;
  height: 35%;
  z-index: 99;
}

.user-2 .top-section {
  background: linear-gradient(45deg, hsl(224deg 58% 51%), hsl(166deg 100% 54%));
}

.card .user-icon {
  position: absolute;
  left: calc(50% - 64px);
  top: calc(35% - 72px);
  z-index: 99;
}

.card .user-icon img {
  width: 128px;
  height: 128px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transform: scale(1);
  transition: all 0.1s ease-out;
}

.card .user-icon img:hover {
  transform: scale(1.08);
}

.card .glare {
  opacity: 0;
}

.card.animated .glare {
  opacity: 0.3;
}

.card.animated.user .glare {
  opacity: 0.15;
}

.card .name {
  color: white;
  font-size: 1.5rem;
}

.card .bottom-section {
  position: absolute;
  top: 35%;
  left: 0;
  background: #0b0b2a;
  width: 100%;
  height: 65%;
  box-sizing: border-box;
  padding-top: 64px;
  text-align: center;
}

.bottom-section .area {
  font-size: 1rem;
  opacity: 0.4;
  display: block;
  line-height: 1rem;
}

.buttons {
  float: left;
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 1rem;
}

.buttons > a {
  border: none;
  width: auto;
  background: #2d2d62;
  padding: 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1rem;
  transition: all 0.1s ease-out;
  transform: scale(1);
  padding: 0.75rem 1rem;
  margin: 0 1rem 0 0;
  border-radius: 100px;
  text-decoration: none;
}

.buttons .main {
  background: #3d26e7;
}

.buttons > .main:hover {
  background: #614df1;
}

.buttons > a:hover {
  background: #494989;
}

.buttons .message svg {
  margin: 0 0.5rem 0 0;
}

.buttons .message {
  margin: 0;
}
