@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&family=Syne:wght@500;600;700&display=swap');

@font-face {
  font-family: 'Satoshi';
  src: url('../../../fonts/Satoshi/Satoshi-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../../../fonts/Satoshi/Satoshi-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../../../fonts/Satoshi/Satoshi-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

$breakpoints: (
  'tablet': 800px,
  'desktop': 1180px,
);

@mixin is_phone() {
  @media only screen and (max-width: map-get($breakpoints, 'tablet')) {
    & {
      @content;
    }
  }
}

@mixin is_tablet() {
  @media only screen and (min-width: map-get($breakpoints, 'tablet')) and (max-width: map-get($breakpoints, 'desktop')) {
    & {
      @content;
    }
  }
}

@mixin is_desktop() {
  @media only screen and (min-width: map-get($breakpoints, 'desktop')) {
    & {
      @content;
    }
  }
}

@mixin is_desktop_tablet {
  @include is_desktop() {
    & {
      @content;
    }
  }

  @include is_tablet() {
    & {
      @content;
    }
  }
}

html {
  padding: 0;
  background-color: white;
  // scroll-behavior: smooth;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  font-size: 10px;

  @include is_desktop_tablet() {
    font-size: 11px;
  }
}

:root {
  /* violet */
  --primary-color: 111, 76, 255;

  --secondary-color: rgb(227, 226, 226);

  /* white */
  --text-color: 256, 256, 256;

  --text-color2: rgb(34, 34, 34);

  a {
    color: black;
  }
}

/* BUTTON */
.btn {
  font-size: 15px;
  font-weight: 550;
  padding: 9px 18px;
  // margin: 1rem;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease;
  text-align: center;
}

.btn-semi-transparent {
  background-color: rgba(var(--primary-color), 0.15);
  border: 1px solid rgba(var(--primary-color), 0.25);
  color: rgba(var(--text-color), 0.8);
}

.btn-secondary {
  background-color: rgba(var(--secondary-color), 0.15);
  border: 1px solid rgba(var(--primary-color), 0.25);
}

.btn-semi-transparent:hover {
  background-color: rgba(var(--primary-color), 0.35);
  border: 1px solid rgba(var(--primary-color), 0.5);
  color: rgba(var(--text-color), 0.9);
}

.btn-glow:hover {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

/* https://sushi.com/ */
.btn-gradient {
  background-image: linear-gradient(to right, rgb(1 134 218), rgb(182 49 167));
  border: 0;
  color: rgba(var(--text-color));
}

.btn-gradient-border:hover {
  border: 2px double transparent;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
    radial-gradient(circle at left top, rgb(217, 0, 192), rgb(1, 110, 218));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}

.btn-gradient-border {
  color: rgba(var(--text-color2));
  border: 2px double transparent;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  transition: background-color 1s;
}

.btn:hover {
  transition: all 0.3s ease;
}

.text-gradient {
  background: linear-gradient(to right, #30cfd0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// find svg gradient way

h1 {
  font-family: 'Syne', sans-serif;
  font-weight: 800;
}

h2 {
  font-family: 'Syne', sans-serif;
  font-weight: 600;
}

h3 {
  font-family: 'Syne', sans-serif;
  font-weight: 600;
}
