@import 'style/global';

#forgot_password,
#reset_password {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  min-height: 50vh;

  h1 {
    font-weight: 700;
    text-align: center;
    max-width: 90%;
  }

  form {
    width: 100%;
    min-width: 300px;
    max-width: 40%;

    @include is_desktop_tablet() {
      min-width: 500px;
    }

    #password_form_error {
      text-align: center;
      color: red;
      font-weight: 600;
    }
  }
}
