@import 'style/global.scss';

.caroussel_container {
  @include is_desktop() {
    position: absolute;
    height: 300px;
    width: 600px;
    margin-left: 50px;
    // top: 60px;
    // left: calc(50% - 300px);
    display: flex;
    margin: auto;
    flex-wrap: nowrap;
    // padding-left: 170px;
  }

  @include is_phone() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    width: 100%;
  }
}

.thecard {
  @include is_desktop() {
    margin-left: -100px;
    &:hover {
      margin-left: -100px;
      margin-right: 100px;
    }
  }
  @include is_phone() {
    margin: 2rem;
  }

  @include is_tablet() {
    margin-left: 0px;
    margin: 2rem;
  }
  transition: 0.4s ease-out;
}

.card:hover {
  // transform: translateY(1px);
  transition: 0.4s ease-out;
}

.card:hover ~ .card {
  position: relative;
  // left: 50px;
  transition: 0.4s ease-out;
}

.title {
  color: white;
  font-weight: 300;
  // position: absolute;
  // left: 20px;
  // top: 15px;
}
