.aa-Panel {
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  min-width: 23%;
}

.aa-SourceHeader {
  color: rgb(128, 128, 128);
}

.search_img_cont {
  width: 30px;
  height: 30px;
  margin: 1.5rem;
}

.search_img {
  // border: 2px solid red;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aa-Form {
  outline: none !important;
  border: 0 !important;
  background-color: rgb(244, 244, 244);
  border-radius: 25px;
  transition-duration: 0.3s;
  &:focus-within {
    background-color: rgb(247, 247, 247);
    outline: none !important;
    border: 0 !important;

    .aa-SubmitIcon {
      color: black !important;
    }
  }
}

.aa-SubmitIcon {
  color: rgb(128, 128, 128) !important;
  &:hover {
    color: rgb(128, 128, 128) !important;
  }
}
