@import 'style/global.scss';

$logo-max-width: 130px;
$logo-max-height: 65px;

.logogrid {
  display: grid;

  align-items: center;
  justify-content: center;
  margin: 1rem;
  grid-auto-flow: column;
  // grid-template-columns: repeat(auto-fill, minmax($logo-max-width * 1.25, 1fr));
  // grid-auto-rows: $logo-max-height * 1.25;
  grid-gap: 3rem;

  @include is_phone {
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    justify-content: center;
  }

  &__item {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__img {
    object-fit: cover;
    max-width: $logo-max-width;
    max-height: $logo-max-height;
    filter: grayscale(80%) contrast(25%);
    transition: filter 0.3s ease;
    border-radius: 5px;

    &:hover {
      filter: grayscale(0) contrast(100%);
    }
  }
}

.press_container {
  margin-top: 4rem;
  h2 {
    width: 80%;
    text-align: center;
    color: black;
    font-size: 2.8rem;
    position: relative;
    margin: 5rem auto;

    @include is_desktop_tablet {
      &:after {
        content: '';
        display: inline;
        width: 30%;
        height: 1px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:before {
        content: '';
        display: inline;
        width: 30%;
        height: 1px;
        background-color: black;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
  }
}
