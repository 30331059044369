@import 'style/global.scss';

.ngo_wrapper {
  display: block;

  .butt {
    width: 50%;
    margin: auto;
  }

  p {
    color: red;
  }

  .ngo_container {
    @include is_phone() {
      margin-top: 0rem;
    }
    width: 100%;
    display: flex;
    float: left;
    margin: 2rem;

    .ngo_item {
      padding-bottom: 2rem;

      &:focus {
        border-bottom: 2px solid rgb(181, 181, 181);
      }

      cursor: pointer;

      img {
        transition-duration: 0.2s;
        transition-property: width;
      }

      &:hover {
        img {
          width: 105px;
        }
      }

      // border-radius: 15px;
      width: 100px;
      height: 150px;
      // padding: 1rem;
      margin: 1rem;

      .theimg {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
        width: 100px;
        height: auto;
        border-radius: 15px;
      }

      text-align: center;

      .Title {
        margin-top: 0.5rem;
        font-size: small;
      }
    }
  }
}
