@import 'style/global.scss';

h1 {
  text-align: center;
}

.accordion {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.accordion-item {
  cursor: pointer;
  border: 2px solid rgb(223, 223, 223);
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  margin: 2rem;
  min-width: 90%;
  border-radius: 2rem;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(34, 47, 43);
  }
}

.accordion-content {
  text-align: left;
  margin-top: 1rem;
}

.accordion-title {
  font-size: larger;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
