@import 'style/global.scss';

body {
  position: relative;
}

footer {
  width: 100%;
  color: white;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  margin: 10rem 0 0 0;

  .row {
    // padding-top: 2rem;
    margin: 3rem;
    display: flex;
    width: 100%;
    font-weight: 650;

    font-size: medium;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include is_phone() {
      padding-right: 2rem;
    }

    img {
      margin: auto;
      @include is_phone() {
        margin: auto;
      }
    }

    .col1 {
      width: 40%;
      margin: 2rem;
      padding: auto;

      @include is_phone() {
        align-items: center;
        width: 10%;
      }
    }

    .col_other {
      width: 15%;
      margin: 1rem;

      div {
        a,
        a:visited {
          color: white;
          text-decoration: none;

          @include is_phone() {
            font-size: xx-small;
            font-weight: 450;
          }
        }

        padding: 1rem;
      }

      .title {
        color: rgb(146, 146, 146);
        margin-bottom: 2rem;
        font-size: larger;

        @include is_phone() {
          font-size: small;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .banner {
    @include is_phone() {
      display: none;
    }
    font-size: 17px;
    font-weight: 600;
    bottom: 10px;
    padding: 2rem;
    color: rgb(255, 255, 255);
    @include is_phone() {
      padding: 0rem;
    }
  }

  .madeBy {
    a {
      color: rgb(255, 255, 255);
      text-decoration: none;
    }
    margin-left: 2rem;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    @include is_phone() {
      bottom: 0;
      left: 0;
      font-size: 1.25rem;
    }
  }
  .social_media_icons {
    padding: 1rem;
    @include is_phone() {
      padding: 0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
    }

    a,
    a:visited {
      color: white;
      font-size: 2rem;
      padding: 0 2rem;
      @include is_phone() {
        padding: 0rem;
      }
    }
  }
}
