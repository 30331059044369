.gallery_card {
  .nft_card_phone_share_ui {
    display: none;
  }
}

.gallery_card.show_phone_share_buttons {
  .nft_card_phone_share_ui {
    box-sizing: border-box;

    border-radius: 10px;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    height: 100%;
    width: 100%;
    padding: 2rem;

    z-index: 20;

    background-color: rgba(0, 0, 0, 0.9);

    h2 {
      color: white;

      text-align: center;

      text-transform: uppercase;
      font-size: 1.5rem;
    }

    .nft_card_phone_share_ui_section {
      width: 90%;

      .nft_card_phone_share_ui_share_buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        a,
        a:visited {
          height: 10%;
          color: white;
          font-size: 2.5rem;
          padding: 0 1.6rem;

          display: flex;
          justify-content: center;
          align-items: center;

          transform: scale(1);
          transition: all 0.2s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
