@import 'style/global.scss';

#how_it_works_section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    width: 80%;
    text-align: center;
    color: black;
    font-size: 2.8rem;
    position: relative;
    margin: 5rem auto;

    @include is_desktop_tablet {
      &:after {
        content: '';
        display: inline;
        width: 30%;
        height: 1px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:before {
        content: '';
        display: inline;
        width: 30%;
        height: 1px;
        background-color: black;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
  }

  #how_it_works {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    width: 90%;

    @include is-desktop() {
      flex-direction: row;
    }

    .hiw_step {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      img {
        max-width: 50px;
      }

      .hiw_step_title {
        font-weight: 600;
        // color:rgb(60, 60, 60);
        // letter-spacing: 1px;
        font-size: 1.6rem;
      }

      .hiw_step_text {
        box-sizing: border-box;
        width: 100%;
        padding: 0 21px 55px 21px;
        text-align: justify;
        font-weight: 500;
        // letter-spacing: 1px;
        color: rgba(0, 0, 0, 0.7);
        font-size: 1.6rem;
      }
    }
  }
}
