@import 'style/global.scss';

.oauth_wrapper {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0;
  @include is_phone() {
    display: block;
  }
  .oauth_but {
    background-color: white;
    cursor: pointer;
    width: 130px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 10px;
    align-items: center;
    margin: 1rem;
    text-align: center;
    padding: 0.5rem;
    vertical-align: middle;
    display: flex;
    justify-content: space-evenly;

    img {
      width: 40px;
      height: 40px;
    }
  }
}
