.gallery_card_infos {
  box-sizing: border-box;

  height: 100%;
  width: 100%;
  padding: 1rem 2rem 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  background-color: white;

  z-index: 10;

  // border: 1px solid blue;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .gallery_card_name {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    word-break: break-all;

    margin: 0;
    margin-top: 1rem;
    width: 100%;

    text-align: center;

    z-index: 10;
  }

  .gallery_card_cause,
  .gallery_card_ngo,
  .gallery_card_donation_amount {
    position: relative;

    &::after {
      position: absolute;
      font-size: 1rem;
      top: -12px;
      left: 0;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
    }
  }

  .gallery_card_cause {
    &::after {
      content: 'cause';
    }
  }

  .gallery_card_ngo {
    &::after {
      content: 'ngo';
    }
  }

  .gallery_card_donation_amount {
    &::after {
      content: 'amount';
    }
  }

  .gallery_card_ngo,
  .gallery_card_cause,
  .gallery_card_donation_addr {
    font-size: 1.6rem;
    font-weight: 500;
    color: #7d7d7d;
    margin: 1rem 0.4rem;
  }

  .gallery_card_donation_addr {
    margin: 0;
  }

  .gallery_card_donation_amount {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 1rem 0.4rem;
  }

  .gallery_card_donation_addr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0.3rem;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.3rem;
      margin: 0;
    }

    img {
      max-width: 30px;
      max-height: 30px;
      // border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      margin-top: 0.1rem;
      padding: 0.4rem;
    }
  }
}
