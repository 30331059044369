.button_wrapper {
  width: 70px;
  height: 70px;
  object-fit: cover;
  // margin-top:1rem;
  // margin-bottom:1rem;
  img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    // border: 1px solid rgb(212, 212, 212);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

  .info {
    &:hover {
      cursor: pointer;
    }
    position: absolute;
    border: 1px solid rgb(243, 243, 243);
    z-index: 9999;
    background-color: white;
    border-radius: 15px;
    transition: 0.4s ease-in-out transform;
    padding: 1.5rem;
    min-width: 400px;
    // max-width: 300px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: black;
    img {
      border: 1px solid rgb(243, 243, 243);
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 15px;
    }
    .info_name {
      padding-top: 0.5rem;
      padding-bottom: 1rem;
      font-size: xx-large;
      font-weight: 600;
    }

    .info_subtitle {
      font-weight: 600;
      font-size: large;
    }

    .tagline {
      text-align: justify;
      font-weight: 400;
    }
    .info_box {
      padding: 1rem;
      display: flex;
      border-top: 1px solid rgb(243, 243, 243);
      justify-content: space-between;
      padding-top: 2rem;
      .info_col2 {
        width: 80px;
        text-align: right;
      }
    }
  }
}
