.input_container {
  &,
  * {
    box-sizing: border-box;
  }

  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin: 0;
  margin-top: 21px;

  .error_text {
    box-sizing: border-box;
    word-wrap: break-word;
    font-size: 1.3rem;
    padding: 0;
    padding-top: 0.2rem;
    margin: 0;
    color: red;
    font-weight: 600;
  }

  label {
    margin-bottom: 1vh;
  }

  input,
  #card_container {
    margin: 7px 0 21px 0;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 400;
    width: 100%;
    font-size: 16px;
    text-align: start;
    border: 0px;
    padding: 16px;
    border: 1px solid #aab7c4;
    border-radius: 5px;

    margin: 0;

    &::placeholder {
      color: #aab7c4;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

input.input_wallet {
  text-transform: uppercase;
  animation: 0.5s in forwards;
}

@keyframes in {
  0% {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}
