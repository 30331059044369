@import 'style/global.scss';

#login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 80vh;

  #login {
    border-radius: 1rem;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    box-sizing: border-box;
    min-height: 60vh;
    width: 100%;
    max-width: 90vw;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    .head_modal {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }

      .modal-close {
        cursor: pointer;
        font-size: larger;
        font-weight: 800;
        float: right;
        margin: 2rem;
      }
    }
    @include is_desktop_tablet() {
      max-width: 40rem;
    }

    #login_title {
      font-size: 2rem;
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 4rem;
    }

    form {
      height: 100%;
      padding: 0 3.4rem;
      width: 80%;
    }

    .login_error_text,
    .register_error_text {
      text-align: center;
      color: red;
    }

    button {
      margin-top: 2rem;
    }

    a {
      font-size: 1.4rem;
      font-family: 'Satoshi', sans-serif;
      color: rgb(95, 99, 103);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
