@import 'style/global.scss';

.ngo_list {
  h2 {
    width: 80%;
    text-align: center;
    color: black;
    font-size: 34px;
    position: relative;
    margin: 5rem auto;
    // font-family: "Syne", sans-serif;
    // font-weight: 500;

    @include is_desktop_tablet {
      &:after {
        content: '';
        display: inline;
        width: 25%;
        height: 1px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 50%;
      }

      &:before {
        content: '';
        display: inline;
        width: 25%;
        height: 1px;
        background-color: black;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
  }

  .ngo_list_grid {
    // box-sizing: border-box;

    // display: grid;
    width: 100%;
    justify-content: center;
    float: left;
    // align-items: center;
    // gap: 2.8rem;
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  }
}
