figure {
  margin: 0 auto;
  max-width: 1100px;
  position: relative;
}
.row {
  margin-bottom: 1.5em;
}
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  @keyframes expand {
    from {
      width: 0%;
    }
    to {
      width: calc(100% - 75px);
    }
  }
}

.chart {
  overflow: hidden;
  width: 100%;
  border-radius: 15px;
  animation: expand 1.5s ease-out;
}
.row + .row .chart {
  animation-delay: 0.2s;
}
.row + .row + .row .chart {
  animation-delay: 0.4s;
}
.block {
  display: block;
  height: 50px;
  color: #fff;
  font-size: 0.55em;
  float: left;
  background-color: #334d5c;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
}
.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
  background-color: #45b29d;
}
.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
  background-color: #efc94c;
}
.block:nth-of-type(4),
.legend li:nth-of-type(4):before {
  background-color: #e27a3f;
}
.block:nth-of-type(5),
.legend li:nth-of-type(5):before {
  background-color: #df5a49;
}
.block:nth-of-type(6),
.legend li:nth-of-type(6):before {
  background-color: #962d3e;
}
.block:hover {
  opacity: 0.65;
}
.value {
  display: block;
  margin: 1rem;
  text-align: center;

  // line-height: 1em;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%);
}
.x-axis {
  text-align: center;
  padding: 0.5em 0 2em;
}
.y-axis {
  height: 20px;
  transform: translate(-32px, 170px) rotate(270deg);
  position: absolute;
  left: 0;
}
.legend {
  margin: 0 auto;
  padding: 0;
  font-size: 0.9em;
}
.legend li {
  display: inline-block;
  padding: 0.25em 1em;
  line-height: 1em;
}
.legend li:before {
  content: '';
  margin-right: 0.5em;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #334d5c;
}
@media screen and (min-width: 768px) {
  h6 {
    padding: 0;
    width: 75px;
    float: left;
    line-height: 100px;
  }
  .block {
    font-size: 1em;
  }
  .legend {
    // width: 50%;
  }
}
