@import 'style/global.scss';

#aci_profile_section {
  box-sizing: border-box;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;

  padding: 55px;
  flex-direction: column;

  @include is_desktop_tablet() {
    flex-direction: row;
  }

  #profile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5rem 1rem;
    width: 100%;
    border-radius: 10px;
    text-align: center;

    font-weight: 600;

    a {
      color: black;
    }

    img {
      width: 80%;
      object-fit: scale-down;
    }

    .profile_username {
      font-size: 34px;
      font-weight: 900;
      margin: 1rem;
    }

    #user_score {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      text-transform: uppercase;

      flex-direction: row;
      font-weight: 700;
      gap: 1rem;

      width: 100%;

      @include is_desktop_tablet() {
        width: 70%;
      }

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex-direction: column;
        font-size: 1.1rem;

        span {
          font-size: 4rem;
          padding: 0;
          margin: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  #donation_grid_container {
    width: 100%;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    margin: 3rem;

    .grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      margin: 2rem;
      font-size: 1.6rem;
      justify-content: left;
    }
  }
}
