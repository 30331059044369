@import 'style/global.scss';

.ngo_card {
  box-sizing: border-box;
  margin: 2rem;
  position: relative;
  background-color: white;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  font-size: 1.6rem;
  cursor: pointer;

  p {
    padding: 1rem;
    font-size: medium;
    text-align: left;
    font-family: 'Satoshi', sans-serif;
  }

  h3 {
    text-transform: none;
  }

  @include is_desktop_tablet() {
    max-width: 370px;
    min-height: 100%;
  }

  .front {
    box-sizing: border-box;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    z-index: 10;
    cursor: pointer;

    .ngo_descr {
      opacity: 0;
      color: white;
      position: absolute;
      bottom: 10%;
    }

    &:hover {
      .ngo_descr {
        opacity: 1;
        top: 10%;
      }

      .ngo_image {
        filter: brightness(40%) grayscale(80%) contrast(120%);
        transition-duration: 0.3s;
      }
    }

    img {
      width: 300px;
      height: 300px;
      border: 1px solid rgb(236, 236, 237);
      object-fit: cover;
    }

    .card_content {
      box-sizing: border-box;
      text-align: left;
      padding: 1rem;

      a {
        text-decoration: none !important;
      }

      .cause_wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        h3 {
          margin: 0;
        }

        .cause_icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      @include is_desktop_tablet {
        justify-content: center;
        flex-direction: column;
        // padding: 21px;
      }
    }

    transition: all 0.3s linear;
  }

  .back {
    box-sizing: border-box;
    color: white;
    transition: all 0.3s linear;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.7;
    }

    img {
      position: absolute;
      top: 0;
      border-radius: 5px;
      // width: 100%;
      // height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .card_content {
      // text-align: start;
      box-sizing: border-box;
      z-index: 10;
      opacity: 1;
      display: flex;
      // width: 80%;
      padding: 21px;

      @include is_desktop_tablet {
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
      }

      p {
        width: 100%;
      }
    }
  }

  .back_animation {
    opacity: 1;
    -webkit-animation-name: fadeInOpacity;
    animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
  }

  .front_animation {
    opacity: 0;
  }

  @-webkit-keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
