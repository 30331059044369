@import 'style/global.scss';

.filter_move {
  align-items: flex-end;
}

.head_filters {
  display: flex;
  justify-content: space-between;
}

.card_container {
  @include is_phone() {
    margin: auto;
    // margin-bottom: 1rem;
  }
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: #fff;
  box-sizing: border-box;
  width: 320px !important;
  height: 420px;
  position: relative;
  border-radius: 10px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  word-break: normal;
  overflow-wrap: break-word;
  .Title {
    position: absolute;
    opacity: 0;
    transition-duration: 0.3s;
    margin: 2rem;
    top: 10%;
    color: white;
    font-size: x-large;
    font-weight: 550;
    word-break: normal;
    overflow-wrap: break-word;
    .subt {
      font-size: medium;
      font-family: 'Courier New', Courier, monospace;
    }
  }
  &:hover {
    .Title {
      opacity: 1;
      top: 10%;
      width: 300px;
      word-break: normal;
      overflow-wrap: break-word;
    }
    .theimg {
      filter: brightness(67%) grayscale(30%) contrast(120%) sepia(36%);
      transition-duration: 0.3s;
    }
    cursor: pointer;
  }

  h3 {
    text-align: center;
    font-size: large;
  }

  .img_container {
    // display: flex;
    // justify-content: center;
  }

  img {
    object-position: top;
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    height: 300px;
    object-fit: cover;
  }

  .bottom_section {
    padding: 1rem;
    padding-bottom: 0rem;

    .column1 {
      @include is_desktop() {
        width: 50%;
      }
    }
  }

  .row {
    color: #757575;
    display: flex;
    padding: 0.5rem;
    font-weight: 550;
    font-size: 15px;
    margin-bottom: 0rem;
    .t1 {
      color: rgb(29, 29, 29);
      flex: 50%;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 1rem;
      height: 20px;
      text-overflow: ellipsis;
    }

    .t2 {
      font-size: x-small;
      width: 70px;
      margin: auto;
      text-align: right;
      font-weight: 400;
    }
    .column1 {
      display: block;
      max-width: none !important;
    }

    .column2 {
      flex: 50%;
      margin: auto;
      text-align: right;
      display: flex;
      float: right;
      flex-direction: row-reverse;
    }
    .elem {
      font-size: large;
      color: rgb(29, 29, 29);
      font-weight: 650;
      margin-top: 0.3rem;
    }
    .column_end {
      width: 30%;
    }
  }

  #row1 {
    border-bottom: 2px solid rgb(243, 243, 243);
  }
}
