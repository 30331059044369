@import 'style/global.scss';

.section_wrapper {
  @include is_phone() {
    margin: 0.5rem;
  }

  margin: 5rem;

  .the_layer {
    margin: 2rem;
    margin-bottom: 10rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    // gap: 1rem;
    justify-content: space-between;
    overflow: hidden;

    @include is_phone() {
      margin: 0rem;
      display: block;
    }
  }

  .section_col {
    @include is_tablet() {
      width: 50%;
    }
    @include is_phone() {
      width: auto;
      min-height: auto;
    }

    width: 40%;
    padding: 1rem;
    vertical-align: middle;
    // margin-left:2rem;
    // padding:2rem;
    min-height: 400px;

    @include is_desktop() {
      // max-width: 28vw;
      // padding-left: 4rem;
    }

    @include is_phone() {
      // margin:2rem;
      display: block;
    }

    // margin:auto;
    h1 {
      line-height: 5rem;
      text-align: left;
      margin-bottom: 3rem;
      margin-top: 9vh;
      // font-weight: 800;
      font-size: 3vw;
      // font-size: 50px;
      @include is_phone() {
        font-size: 33px;
        margin-bottom: 0rem;
        line-height: normal;
        margin-top: 0rem;
      }
      // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      div {
        width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }

    .redo {
      @include is_phone() {
        display: none;
      }

      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: left;
      font-size: larger;
      display: flex;
      align-items: center;
      padding: 1rem;
      border-radius: 50px;
      cursor: pointer;
    }

    .subtitle {
      @include is_phone() {
        font-size: 16px;
        font-weight: 450;
      }
      font-weight: 500;
      font-size: larger;
      color: #223d40;
      font-family: 'Satoshi', sans-serif;
    }

    .section_but {
      display: flex;
      margin-top: 2rem;

      button {
        margin-right: 1rem;
      }
    }
  }

  .section_col2 {
    width: 50%;
    justify-content: right;
    align-items: center;

    @include is_tablet() {
    }

    @include is_phone() {
      margin: auto;
      width: 100%;
    }
  }
}
