@import 'style/global.scss';

.wallet_menu_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 997;
  background-color: rgba(0, 0, 0, 0.7);
  // z-index: 9999;

  .backButt {
    position: absolute;
    top: 1%;
    left: 1%;
    cursor: pointer;
  }

  .wallet_info {
    text-align: center;
    display: flex;
    gap: 1rem;
    justify-content: center;
    @include is_phone() {
      width: auto;
    }
    // position: absolute;
    z-index: 9999;
    cursor: pointer;
    &:hover {
      color: rgb(121, 121, 120);
    }

    padding: 1rem;
  }

  .wallet_menu {
    z-index: 998;
    margin: auto;
    max-width: 100vw;
    background-color: white;
    left: 50%;
    top: 50%;
    position: absolute;
    padding: 1rem;
    border-radius: 15px;
    border: 1px solid rgb(228, 228, 228);
    width: 500px;
    transform: translate(-50%, -50%);

    .balance_display {
      display: flex;
      justify-content: space-evenly;
      margin: 2rem;
      //  width: 50%;

      .col {
        display: block;

        .matic_equiv {
          color: rgb(169, 169, 169);
        }
      }

      img {
        margin: auto;
        width: 20px;
        height: 20px;
      }

      h2 {
        margin: 0;
      }
    }

    h1 {
      margin-bottom: 0.3rem;
      font-size: large;
    }

    .wallet_line {
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 1px;
      background-color: rgb(245, 245, 245);
    }

    .modal_close {
      position: absolute;
      top: 3%;
      right: 3%;
      cursor: pointer;
    }

    .wallet_address {
      margin: auto;
      text-align: center;
      border-radius: 25px;
      font-size: smaller;
      white-space: nowrap;
      width: 20%;
      padding: 0.5rem;
      overflow: hidden;
      cursor: pointer;
      text-overflow: ellipsis;

      &:hover {
        background-color: rgb(245, 245, 245);
      }
    }
    .wallet_ic {
      cursor: pointer;
      // width: 70%;
      margin-top: 1rem;
      background-color: rgb(249, 249, 249);
      border-radius: 10px;
      padding: 1rem;
      text-align: center;
      border-bottom: 1px solid rgb(130, 130, 179);
      display: flex;
      justify-content: center;
      gap: 0.3rem;

      &:hover {
        background-color: rgb(234, 232, 232);
        color: rgb(52, 52, 52);
      }

      &:active {
        box-shadow: 0 5px #666;
        transform: translateY(4px);
      }

      img {
        width: 25px;
        height: 25px;
      }

      .selected {
        border-bottom: 3px solid rgb(86, 86, 207);
      }
    }

    .wallet_content {
      .option_title {
        // padding: 1rem;
        // padding-bottom:0.2rem;
        font-size: 1.5rem;
        font-weight: 550;
      }

      margin: 1rem;

      p {
        font-size: 1.5rem;
        margin-block-start: 0.3em;
        margin-block-end: 0.3em;
        // font-weight: 550;
      }
    }

    .option_wrapper {
      padding: 1rem;
      border-radius: 15px;
      // border: 1px solid rgb(245, 245, 245);
    }
  }
}
