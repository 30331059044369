.loader_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  font-size: 1.7rem;

  .checkmark {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    border: 0;
    border-right: 4px solid #7ac142;
    animation: 1s spin infinite;
  }
}
