.collection_imgs {
  font-weight: 500;
}

.preview_avatar {
  position: absolute;
  width: 100px;
  height: 100px;
  transform: translate(-50%, 50%);
  z-index: 2;
  // top: 50%;
  left: 50%;
  img {
    border-radius: 10px;
    border: 1px solid gainsboro;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
