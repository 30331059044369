.right_comp {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: auto;
  width: 80%;
  // min-height: 80%;
  border-radius: 20px;
  // border: 1px solid rgb(191, 55, 55);
  border: 1px solid rgb(232, 231, 231);
  // font-size: xx-large;
  padding: 2rem;

  .title {
    color: rgb(132, 132, 132);
    font-size: x-large;
  }

  .price {
    font-size: 4rem;
    text-align: left;
    margin: 0;
  }

  .elem {
    margin-top: 0.2rem;
    font-size: large !important;
    color: rgb(29, 29, 29);
    font-weight: 700;
  }

  .user_avat img {
    border-radius: 50%;
    width: 100px;
    height: auto;
  }
}
