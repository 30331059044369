@import 'style/global.scss';

.cookie_cont {
  position: fixed;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 10%;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(238, 238, 238);

  @include is_phone() {
    flex-direction: column;
    height: auto;
  }
}

.tab_pannel {
  width: 100% !important;
}

.profile_header {
  position: relative;

  .wallet_info {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    padding: 1rem;

    @include is_phone() {
      width: auto;
    }
    &:hover {
      color: rgb(121, 121, 120);
    }
  }

  #profile_artist_butt {
    padding: 3px 18px;
    font-size: small;
    gap: 0.5rem;
  }

  #profile_perso {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;
    align-items: center;
    margin-top: -10rem;
    height: 180px;

    @include is_phone() {
      margin-top: -20rem;
    }

    img {
      background-color: white;
      border: 5px solid rgb(255, 255, 255);
      border-radius: 500px;
      width: 180px;
      height: 180px;
      object-fit: cover;
    }

    .update_input_container {
      width: 100%;
      max-width: 32rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      label {
        margin: 10px 0;
      }
    }

    .file_upload {
      input[type='file'] {
        display: none;
      }

      position: relative;
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      // border: 5px solid rgb(255, 255, 255);

      &::after {
        z-index: 2;
        content: 'change avatar';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        text-transform: uppercase;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.9);
        color: black;
        font-weight: 600;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.25s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .space {
    position: relative;
    width: 100%;
    height: 400px;
  }

  .profile_menu {
    padding-left: 3rem;
    padding-right: 3rem;

    @include is_phone() {
      padding: 1rem;
    }

    .rsk_title {
      font-family: 'Courier New', Courier, monospace;
      font-weight: 700;
    }

    .line {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include is_phone() {
        flex-direction: column;
      }

      .user_tools {
        padding: 1rem;

        .edit_but {
          display: flex;
          align-items: center;

          svg {
            font-size: 2rem;
          }

          padding: 1rem;
          border-radius: 50px;
          cursor: pointer;

          // border: 1px solid rgb(209, 209, 209);
          &:hover {
            background-color: rgb(233, 233, 233);
          }
        }
      }
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: left;

    @include is_phone() {
    }

    font-size: 4rem;
    font-weight: 700;
  }

  .profile_img {
    &:hover {
      img {
        filter: brightness(99%);
      }
    }

    cursor: pointer;
    position: absolute;
    width: 200px;
    height: 200px;
    object-fit: cover;
    // top: 100%;
    top: 0;
    left: 50%;
    border-radius: 50%;
    border: 1.5px solid rgb(175, 175, 175);
    background-color: white;
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .public_profile_img {
    position: absolute;
    width: 200px;
    height: 200px;
    object-fit: cover;
    top: 100%;
    left: 10%;
    border-radius: 50%;
    border: 1.5px solid rgb(175, 175, 175);
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .profile_box {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 400px;
    height: 80px;
    background-color: white;
    color: #7c7c7c;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(212, 212, 212);

    @include is_phone() {
      width: 80%;
    }

    .row {
      display: flex;
      padding: 2rem;
      font-weight: 650;
      font-size: medium;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .column {
        @include is_phone() {
          margin: 0.3rem;
          padding: 1rem;
        }

        // margin: 2rem;
        .elem {
          margin-top: 0.2rem;
          font-size: large !important;
          color: rgb(29, 29, 29);
          font-weight: 700;
        }
      }
    }
  }
}

.profile_cover {
  position: absolute;
  width: 100%;
  object-fit: cover;
  max-height: 400px;
  min-height: 300px;
  height: auto;
  filter: brightness(70%);
}

.confirm {
  position: absolute;
  // width: 100px;
  z-index: 9999;
  transform: translate(-50%, 0%);
  left: 50%;
}

#profile_section {
  #profile {
    z-index: -1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;

    img {
      border: 7px solid;
      border-radius: 500px;
      width: 180px;
      height: 180px;
      object-fit: cover;
      cursor: pointer;
    }

    #user_score {
      display: flex;
      justify-content: left;
      font-size: 1rem;
      flex-direction: row;
      font-weight: 700;
      gap: 1rem;
      width: 100%;

      @include is_desktop_tablet() {
        // width: 40%;
      }

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 10%;
        flex-direction: column;
        font-size: 1.1rem;

        span {
          font-size: 4rem;
          padding: 0;
          margin: 0;
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  #donation_grid_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2rem;

    .grid {
      // margin-top: 10rem;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      gap: 5rem;
      font-size: 1.6rem;
      justify-content: left;
    }
  }
}
