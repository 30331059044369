@import 'style/global.scss';

#edit_profile_section {
  h1 {
    text-align: left;
  }
}

#artist_btn {
  // width: 100px;
  font-weight: 500;
  font-size: smaller;
  padding: 6px 9px;
  // letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  // cursor:default;
}

#simple-tabpanel-0,
#simple-tabpanel-1,
#simple-tabpanel-2 {
  width: 80%;
}

.social_networks {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

#profile_update {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include is_desktop() {
    min-width: 60%;
    min-width: 370px;
  }

  img {
    border-radius: 500px;
    width: 200px;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .update_input_container {
    width: 100%;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      margin: 10px 0;
    }
  }

  .file_upload {
    input[type='file'] {
      display: none;
    }

    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;

    img {
      border-radius: 500px;
      width: 180px;
      height: 180px;
      object-fit: cover;
    }

    &::after {
      content: 'change avatar';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.9);
      color: black;
      font-weight: 600;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.25s;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}

.danger_zone {
  button {
    background-color: white;
    border: 1px solid red;
    color: red;

    &:hover {
      background-color: red;
      color: white;
    }
  }
}

#danger_zone_modal_buttons {
  display: flex;
  gap: 1rem;
}
