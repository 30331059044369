.input_funds_wrapper {
  // height: 50px;
  position: relative;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgb(208, 207, 207);
  &:hover {
    border: 1px solid rgb(79, 79, 79);
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .line {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .currency {
    // height: 20px;
    font-size: x-large;
    text-align: center;
    font-weight: 600;
    // width: 20px;
    // margin:0.2rem;
  }
  input[type='number'] {
    height: 20px;
    font-size: x-large;
    font-weight: 600;
    border: none;
    padding: 0rem;
  }
  input:focus {
    border: none;
    outline-width: 0;
  }

  .input_equiv {
    font-size: medium;
    color: rgb(169, 169, 169);
  }

  .swicher {
    position: absolute;
    right: 5%;
    // top:0;
    transform: translate(0, 50%);
    //   bottom:50%;
    // width: 30px;
    // height: 30px;
    border-radius: 50%;
    svg {
      text-align: center;
      vertical-align: middle;
      font-size: x-large;
      &:hover {
        color: rgb(159, 159, 159);
        cursor: pointer;
      }
    }
  }
}
