@import 'style/global.scss';

.artwork_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

// ALGOLIA
.ais-Hits-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // margin-left: 1rem;
  // margin-right: 2rem;
  margin: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;

  .card_container {
    margin-bottom: 2rem;
  }
}

.ais-SearchBox {
  // background-color: rgb(243, 243, 243);
  border: 2px solid rgb(241, 239, 239);
  border-radius: 10px;
  // margin:1rem;
  padding: 1rem;

  &:hover {
    border: 2px solid rgb(181, 181, 181);
  }
}

.filter_moved {
  display: none;
  margin: 2rem;
}

.ais-SearchBox-submit {
  border: none;
  background-color: transparent;
}

.ais-SearchBox-submitIcon {
  color: rgb(52, 52, 52);
  visibility: hidden;
}

.ais-SearchBox-form {
  // height: 50px;
  margin: auto;
  display: flex;
  // align-content: space-between;
}

.ais-SearchBox-input {
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  width: auto;
  outline: none !important;
  border: 0 !important;
  background-color: transparent;
}

.ais-Hits-item {
  .card_container {
    width: 350px !important;

    @include is_phone() {
      width: 320px !important;
    }
  }
}

.ais-Pagination-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-left: 40%;
  margin-right: 40%;
  padding: 2rem;
  justify-content: space-evenly;
  font-weight: 600;
  font-size: large;
  a,
  a:visited {
    text-decoration: none;
    color: black;
  }
}

.ais-SortBy-select {
  padding: 1rem;
  border: 1px solid rgb(235, 235, 237);
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 10px;
  font-family: inherit;
  color: #4d4d4d;
  // border: thin solid rgba(77, 77, 77, 0.3);
  border-radius: 4px;
}

.ais-SortBy {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 5rem;
}

.item_layer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 2rem;

  ol li,
  ul {
    list-style: none;
  }

  .filters {
    @include is_phone() {
      display: none;
    }

    margin-left: 2rem;
    width: 100%;
    max-width: 290px;

    .ais-SearchBox-reset {
      border: none;
      background-color: transparent;
    }

    .f_title {
      font-size: x-large;
      font-weight: 700;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .ais-RefinementList-item {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
      padding: 2rem;
      border: 2px solid rgb(241, 239, 239);
      width: auto;
      border-radius: 10px;

      &:hover {
        border: 2px solid rgb(181, 181, 181);
      }

      label {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      .ais-RefinementList-label {
        font-size: large;
        font-weight: 600;
      }
    }

    .ais-RefinementList-checkbox {
      transform: scale(2);
    }

    ol li,
    ul {
      margin-block-start: 0em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0px;
    }

    flex: 100%;
    padding: 1rem;
  }

  .col2 {
    flex: auto;
  }
}
