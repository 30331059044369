@import 'style/global.scss';

#login,
#registration {
  .black_link {
    color: white !important;
    &:hover {
      color: black !important;
    }
  }
  box-sizing: border-box;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    font-family: 'Satoshi', sans-serif;
    color: rgb(95, 99, 103);
    &:hover {
      text-decoration: underline;
    }
    text-decoration: none;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }

  h3 {
    //  ;
    font-size: 34px;
    margin: 0;
    margin-top: 34px;
  }

  form {
    // padding: 34px;
    width: 80%;

    @include is_desktop_tablet() {
      width: 40%;
    }
  }

  .login_error_text,
  .register_error_text {
    color: red;
  }
}
