@import 'style/global.scss';

.but_container {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-content: space-around;
  border-radius: 25px;
  color: black;
  padding: 0.3rem;

  .info {
    position: absolute;
    border: 1px solid rgb(243, 243, 243);
    z-index: 2;
    background-color: white;
    border-radius: 15px;
    transition: 0.4s ease-in-out transform;
    padding: 1.5rem;
    min-width: 200px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    img {
      border: 1px solid rgb(243, 243, 243);
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 50%;
    }
    .info_name {
      padding-top: 0.5rem;
      padding-bottom: 1rem;
      font-size: x-large;
    }
    .info_box {
      padding: 1rem;
      display: flex;
      border-top: 1px solid rgb(243, 243, 243);
      justify-content: space-between;
      padding-top: 2rem;
      .info_col {
        width: 80px;
      }
      .info_col2 {
        width: 80px;
        text-align: right;
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      width: 100%;
    }
    .avatar_img {
      border-radius: 50% !important;
      width: 40px;
      height: 40px;
      margin-bottom: 0;
    }
  }

  .wrapper {
    padding: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    padding-bottom: 0px !important;
    font-size: x-small;
    color: rgb(154, 154, 154);
  }
}
.artist_name {
  font-size: smaller !important;
}
