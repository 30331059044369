@import 'style/global.scss';

#leaderboard_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  #leaderboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    @include is_desktop_tablet() {
      width: 80%;
    }
    min-width: 300px;
    max-width: 800px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 4rem;
    padding: 2rem;

    h1 {
      font-size: 2rem;
    }

    table {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      border-spacing: 0;
      border-radius: 0.3rem;
      overflow: hidden;
    }

    tr {
      display: flex;
      box-sizing: border-box;
      background-color: white;

      margin: 0;
      padding: 2rem;
      min-height: 4rem;

      border-radius: 0.1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      cursor: pointer;

      .leaderboard_index {
        width: 10%;
      }

      .leaderboard_total_given,
      .leaderboard_total_donations {
        width: 30%;
        justify-content: center;
      }

      .leaderboard_index,
      .leaderboard_total_given,
      .leaderboard_total_donations {
        font-weight: 600;
      }

      .leaderboard_user {
        display: flex;
        gap: 1rem;
        font-weight: 600;
      }

      .leaderboard_user_header {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
      }

      &:last-of-type {
        border: 0;
      }
    }

    tbody tr {
      transition: all 0.1s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    td,
    th {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }

    td {
      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }
}
