@import 'style/global.scss';

#email_confirmation {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  #confirmation_status {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p {
      font-size: 34px;
    }
  }
}
