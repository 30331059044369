@import 'style/global.scss';

#collec {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transition-duration: 1000ms;

  &:hover {
    // border: 1px solid rgb(231, 231, 231);
    background-color: rgb(247, 247, 247);
  }
}

.collection_card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  flex-wrap: wrap;
  border-radius: 15px;
  gap: 2rem;
  @include is_phone() {
    padding: 0rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  .column1 {
    @include is_desktop() {
      width: 20%;
    }
    @include is_phone() {
      padding: 1rem;
      width: 100%;
    }
    @include is_tablet() {
      width: 50%;
    }
  }

  .column2_collec {
    display: flex;
    gap: 1rem;
    @include is_desktop() {
      flex-wrap: wrap;
      width: auto;
    }

    flex-wrap: wrap;
    @include is_phone() {
      justify-content: center;
    }
  }

  .column {
    margin: 1rem;
    @include is_phone() {
      margin: 1rem;
    }
  }
}

.collection-img {
  border-radius: 10px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

h2 {
  font-size: 30px;
  // font-weight: 00;
  // text-transform: uppercase;
  //  ;
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  // font-weight: 600;
  text-transform: uppercase;
  //  ;
  margin-bottom: 0.5rem;
}
.descr {
  @include is_phone() {
  }
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
}

#elems {
  position: relative;
  font-size: 15px;
  font-weight: 400;
}

.notif {
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
  background-color: rgb(248, 248, 248);
  border-radius: 10px;
  line-height: 20px;
  text-align: left;

  .el {
    padding: 0.5rem;
    margin: auto;
  }
}
