.gallery_card {
  .gallery_card_image {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    aspect-ratio: 1;

    z-index: 10;

    img {
      width: 100%;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
