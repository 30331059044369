@import 'style/global.scss';

:root {
  --aa-detached-modal-media-query: (min-width: 1000px);
}

#mobile_menu {
  box-sizing: border-box;
  position: relative;
  z-index: 100;

  #mobile_nav_retractable.closed {
    height: 100vh;
    height: -webkit-fill-available;
    display: none;
  }

  #mobile_top_nav {
    display: flex;
    justify-content: space-between;
    padding: 1em 1.6rem;
    align-items: center;
    &_button {
      display: flex;
    }
  }

  #mobile_mid_nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2.8rem;

    #mobile_mid_nav_title {
      font-size: 1.6rem;
      font-weight: 800;
      padding: 0.5rem 1rem;
    }

    li {
      margin-bottom: 1.6rem;
      display: list-item;
      font-weight: 500;

      a {
        color: #000000;
        font-size: 1.6rem;
        text-decoration: none;
        line-height: 2.8rem;
        padding: 0.5rem 1rem;
      }
    }
  }

  #mobile_bot_nav {
    font-size: 1.6rem;
    padding: 1.6rem 0;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.2rem;

    a {
      padding: 0.5rem 1.6rem;

      font-weight: 700;
      text-decoration: none;

      color: black;
    }

    .nav_register {
      background-color: black;
      color: white;
      border-radius: 5px;
      padding: 1rem 1.6rem;
    }
  }

  #mobile_nav_switch {
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0;

    .hamburger {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.icons {
  font-size: 55px;
  color: white;
  margin: 10px;
}

.profile {
  button {
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    font-size: 21px;
    transition: all 0.1s;
    margin-right: 21px;

    * {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.displayer {
  display: flex;
}
