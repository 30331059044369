#profile_nav {
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  font-size: 1.6rem;
  z-index: 100;
  height: 40px;

  img {
    border-radius: 50%;
    display: block;
    margin: 0px !important;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }

  #profile_menu {
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: 2rem;
    // right: 5rem;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    list-style: none;
    padding: 0;

    li {
      box-sizing: border-box;
      max-height: 5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      color: black;

      font-weight: 400;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;

      &:hover {
        background-color: rgb(0, 0, 0);
        color: white;
      }

      // logout
      &:first-of-type {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      &:last-of-type {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }

  .open_profile_menu {
    display: block !important;
  }

  .closed_wallet {
    display: none;
  }

  .open_wallet_menu {
    position: absolute;
    display: block;
    border: 2px solid gainsboro;
    border-radius: 15px;
    width: 300px;
    height: 500px;
    top: 50px;
    background-color: white;
  }
}

.nav_wallet {
  position: relative;
  cursor: pointer;
  background-color: rgb(236, 234, 234);
  border-radius: 10px;
  display: block;
  text-align: center;
  width: 40px;
  margin: auto;
  display: block;
  height: 40px;
  margin: 1rem;
  &:hover {
    background-color: rgb(244, 243, 243);
  }
  svg {
    margin: 1rem;
    padding: auto;
  }
}
