@import 'style/global.scss';

#contact_section {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 34px;
    font-weight: 900;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    width: 80%;

    @include is_desktop() {
      width: 50%;
    }
  }
}

.form_status {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .status_text {
    font-size: 21px;
    color: white;
    padding: 13px;
  }
}
