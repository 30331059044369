@import 'style/global.scss';

#nft_page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 1rem;

  box-sizing: border-box;

  background-color: white;
  width: 90%;
  padding: 3rem 0rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  margin: 1rem auto;

  @include is_desktop_tablet() {
    flex-direction: row;
    justify-content: space-around;
  }

  #nft_page_artwork {
    max-width: 320px;
  }

  #nft_page_infos {
    display: flex;
    flex-direction: column;

    font-size: 2rem;
    max-width: 80%;

    @include is_desktop_tablet() {
      max-width: 40%;
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 90%;
      height: 1.2em;
      white-space: nowrap;
    }
  }
}
