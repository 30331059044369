@import 'style/global.scss';

a.black_link {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  font-size: 1.3rem;

  background-color: black;
  border: 2px solid black;
  color: white;
  border-radius: 5px;

  margin: 13px 0;
  height: 55px;
  width: 100%;

  transition: all 0.5s;
  cursor: pointer;

  max-width: 300px;

  @include is_desktop_tablet() {
    max-width: 400px;
    // width: 20%;
  }

  &:hover {
    background-color: white;
    color: black;
  }
}

a.white_link {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3rem;

  background-color: white;
  border: 2px solid white;
  color: black;
  border-radius: 5px;

  margin: 13px 0;
  width: 100%;
  height: 55px;

  transition: all 0.5s;
  cursor: pointer;

  max-width: 300px;

  @include is_desktop_tablet() {
    max-width: 400px;
  }

  &:hover {
    background-color: black;
    color: white;
  }
}
