@import 'style/global.scss';

header {
  background-color: white;
  margin: 0 !important;
  font-family: 'Satoshi', sans-serif;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

#top_nav img {
  width: 4rem;
  height: auto;
  @include is_phone() {
    margin: auto;
    // width: auto;
    width: 5rem;
  }
}
