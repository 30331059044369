@import 'style/global.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 997;
  background-color: rgba(0, 0, 0, 0.7);

  .card_container {
    margin: auto;
  }
}
.head_modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .modal-close {
    cursor: pointer;
    font-size: larger;
    font-weight: 800;
    float: right;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;

  h1 {
    text-align: left;
  }
}

.modal {
  @include is_phone() {
    height: auto;
    width: 100vw;
  }

  z-index: 100;
  position: relative;
  margin: auto;
  border-radius: 5px;
  width: 80vw;
  height: 80vh;
  padding: 1rem;
}

.modal-container {
  height: 80%;

  .matic {
    width: 13px;
    height: 13px;
    object-fit: cover;
    margin-left: 0.3rem;
  }

  @include is_phone() {
    height: auto;
  }

  .modal_row {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    margin: 2rem;
    font-weight: 550;
    font-size: 15px;
    justify-content: space-between;

    @include is_phone() {
      flex-direction: column-reverse;
    }

    .col1 {
      width: 50%;
      background: rgb(255, 255, 255);
      border-radius: 15px;
      margin: auto;

      @include is_phone() {
        width: auto;
        border-right: 0px solid gainsboro;
      }

      .form {
        width: 30%;
        margin-top: 1rem;

        @include is_phone() {
          width: auto;
        }
      }

      padding: 2rem;
      align-items: center;

      h1 {
        margin-top: 0.5rem;
      }
    }

    .col2 {
      @include is_phone() {
        width: 100%;
        padding: 0rem;
        display: none;
      }

      width: 50%;
      padding: 2rem;
    }

    p {
      line-height: 2rem;
    }
  }

  .ngo_flag {
    position: absolute;
    top: 7%;
    left: 44%;
  }

  h2 {
    margin-bottom: 0.1rem;
    padding-bottom: 0;
  }

  table {
    margin-top: 0rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    inline-size: 100%;
  }

  tbody {
    color: #b4b4b4;
  }

  td {
    padding-block: 0.2em;
  }

  tfoot {
    border-top: 1px solid #eaeaea;
    font-weight: 600;
  }
}
.to_np {
  // font-size: small;
  padding-left: 1rem;
  // font-weight: 400;
  // font-family: 'Courier New', Courier, monospace;
  color: rgb(147, 197, 179);
}

.am_np {
  color: rgb(147, 197, 179);
}
