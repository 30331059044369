.reskue_text_input_container {
  position: relative;
  margin: 2rem 0;

  input {
    height: 5rem;
    width: 100%;
    border: 1.5px solid #d6d6d6;
    border-radius: 15px;
    box-sizing: border-box;
    transition-duration: 0.5s;

    &:focus {
      outline: none;
      border: 1.5px solid #919191;
    }

    &:not(input[value='']) + .placeholder_text p {
      font-size: 1.1rem;
      color: black;
      transform: translate(0, -200%);
    }

    &:focus + .placeholder_text p {
      box-sizing: border-box;
      font-size: 1.1rem;
      transform: translate(0, -200%);
      color: #919191 !important;
    }
  }

  .placeholder_text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;

    p {
      font-size: 1.4rem;
      padding: 0 0.5rem;
      background-color: white;
      color: #5f6367;
      transform: translate(0);
      transition: transform 0.15s ease-out, font-size 0.15s ease-out,
        background-color 0.2s ease-out, color 0.15s ease-out;
    }
  }

  input,
  .placeholder_text {
    font-size: 1.4rem;
    padding: 0 1.2rem;
  }
}
