@import 'style/global.scss';

#collections_wrapper {
  h1 {
    text-align: center;
    margin-bottom: 5rem;
    margin-top: 9vh;
    font-size: 34px;
  }
}

.collections_container {
  @include is_phone() {
    margin: 0.2rem;
  }

  margin: 2rem;
}
