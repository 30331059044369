@import 'style/global.scss';

.collection_header {
  position: relative;

  h1 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: xxx-large;

    @include is_phone() {
      font-size: x-large;
    }
  }

  .collection_info {
    margin-top: -5rem;
  }

  .collection_img {
    @include is_phone() {
      top: 60%;
    }
    position: absolute;
    width: 150px;
    height: 150px;
    object-fit: cover;
    top: 65%;
    left: 50%;
    border-radius: 10px;
    border: 1.5px solid rgb(175, 175, 175);
    transform: translate(-50%, -50%);
  }
}

.collection_box {
  transform: translate(0, -40%);
  // position: absolute;
  // top: 100%;
  // left: 50%;
  width: 40%;
  margin: auto;
  // height: 80px;
  background-color: white;
  color: #8b8b8b;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  // border:1px solid rgb(212, 212, 212);
  @include is_phone() {
    width: 80%;
  }

  .row {
    gap: 0rem 3rem;
    display: flex;
    font-weight: 600;
    font-size: medium;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;

    // justify-content: space-between;
    .column {
      padding: 1rem;

      // margin:auto;
      @include is_phone() {
        margin: 0.3rem;
        padding: 1rem;
      }

      .descr {
        text-align: justify;
        font-weight: 500;
        color: rgb(36, 36, 36);
      }

      .size {
        font-weight: 600;
        font-size: x-large;
        color: rgb(36, 36, 36);
      }

      // margin: 2rem;
      .elem {
        margin-top: 0.5rem;
      }
    }
  }
}

.collection_cover {
  width: 100%;
  object-fit: cover;
  max-height: 600px;
  min-height: 500px;
  height: auto;
  filter: brightness(70%);
}

.collection_list {
  margin-top: -7rem;
}

.collection_filter {
  display: none;
}
