button.black_button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  width: 100%;
  height: 55px;
  margin: 13px 0;

  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;

  color: white;
  background-color: black;
  border: 2px solid black;
  border-radius: 5px;

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    background-color: white;
    color: black;
  }
}

.black_button_loading {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 1.5em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1s spin infinite linear;
  }
}

button.black_button.white {
  color: black;
  background-color: white;

  &:hover {
    color: white;
    background-color: black;
  }
}

button.black_button.red {
  color: white;
  background-color: red;
  border: 2px solid red;

  &:hover {
    color: red;
    background-color: white;
  }
}

/** White button */

button.white_button {
  display: flex;
  cursor: pointer;
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 55px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  margin: 13px 0;
  transition: all 0.5s;
  color: black;

  &:hover {
    background-color: black;
    color: white;
  }
}

.white_button_loading {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    font-size: 1.5em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1s spin infinite linear;
  }
}

/** Animations */

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
