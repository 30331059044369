@import 'style/global.scss';

.ngo_header {
  z-index: 0 !important;
  position: relative;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: xxx-large;
    text-align: center;

    @include is_phone() {
      top: 30%;
    }
  }

  .ngo_cover {
    position: relative;
    width: 100%;
    object-fit: cover;
    max-height: 400px;
    min-height: 300px;
    height: auto;
    filter: brightness(70%);
  }
}

#ngo_section {
  .cause_logo {
    width: 100%;
    height: auto;
  }

  .ngo_img {
    // cursor: pointer;
    // position: absolute;
    width: 200px;
    height: 200px;
    object-fit: cover;
    // top: 100%;
    // left: 5%;
    border-radius: 15px;
    border: 1.5px solid rgb(175, 175, 175);
    // transform: translate(0, -50%);

    // img {
    //     width: 200px;
    //     height: 200px;
    // }

    @include is_phone() {
      width: 200px;
      height: 200px;
      // left: 50%;
      // transform: translate(50%, 0%);
    }
  }

  .wallet_info {
    img {
      margin-right: 1vw;
    }
    margin-left: 3vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    // justify-content: center;
    @include is_phone() {
      width: auto;
      align-items: center;
      margin-left: 0px;
    }
    z-index: 9999;
    cursor: pointer;
    .wallet_address {
      &:hover {
        color: rgb(121, 121, 120);
      }
    }
    .icon-website {
      margin-right: 1vw;
    }
    a {
      text-decoration: none;
    }
    padding: 1rem;
  }
  .social_media_icons {
    // // position: absolute;
    padding: 1rem;
    // left: 12%;
    // transform: translate(-50%, 0%);

    @include is_phone() {
      left: 50%;
      top: 140%;
    }

    a {
      padding: 1rem;
    }

    a:visited {
      color: black;
    }
  }

  .ngo_container {
    position: relative;
    z-index: 1 !important;
    // margin-top: -9rem;

    .row {
      @include is_desktop() {
        width: 90%;
        margin: auto;
      }
      margin: auto;
      min-height: 40%;
      background-color: white;
      color: #7c7c7c;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      display: flex;
      padding: 2rem;
      font-weight: 650;
      font-size: medium;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: justify;

      .column {
        padding: 1rem;

        @include is_phone() {
          margin: 0.3rem;
          padding: 1rem;
        }

        .elem {
          margin-top: 0.2rem;
          color: rgb(29, 29, 29);
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  .ngo_info {
    margin: 2rem;
    margin-top: 3%;
    position: relative;
    border-radius: 10px;

    text-align: left;
    border-radius: 15px;
  }
}

.reskue_brands {
  h2 {
    text-align: center;
  }

  img {
    max-width: 300px;
  }
}
