.but_container {
  button {
    padding: 1rem;
    border: 0px solid black;
    background-color: rgb(250, 249, 249);
    min-width: 70px;
    font-weight: 500;
    border-radius: 10px;
    font-size: medium;

    height: 50px;
    transition: all 0.3s ease;
    &:hover {
      background-color: black;
      color: white;
      width: auto;
      cursor: pointer;
    }
  }

  .polygon_token {
    width: 17px;
    height: 17px;
    object-fit: cover;
    align-items: center;
  }

  button:hover:before {
    content: 'Reskue';
  }

  button:hover span {
    display: none;
  }

  .but_card {
  }

  .eqv {
    margin-top: 0.1rem;
    font-size: smaller;
    font-weight: 400;
    color: rgb(160, 160, 160);
  }
}

.wrapper {
  text-align: left;
}
